import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/HomeView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/applications',
    name: 'Applications',
    component: () => import(/* webpackChunkName: "Applications" */ '../views/applications/ApplicationsView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/applications/:applicationId/roles',
    name: 'Roles from Application',
    component: () => import(/* webpackChunkName: "dataSourceView" */ '../views/roles/RolesView.vue'),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: '/applications/:applicationId/users',
    name: 'Users from Application',
    component: () => import(/* webpackChunkName: "dataSourceView" */ '../views/users/UsersView.vue'),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: '/users',
    name: 'Users',
    props: true,
    component: () => import(/* webpackChunkName: "Users" */ '../views/users/UsersView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/manage-profile',
    name: 'Manage profile',
    props: true,
    component: () => import(/* webpackChunkName: "Users" */ '../views/users/UsersView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/clients',
    name: 'Clients',
    component: () => import(/* webpackChunkName: "Clients" */ '../views/clients/ClientsView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/reports',
    name: 'Reports',
    props: true,
    component: () => import(/* webpackChunkName: "Reports" */ '../views/reports/ReportsView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/reports/edit',
    name: 'ReportEdit',
    props: true,
    component: () => import(/* webpackChunkName: "ReportsEdit" */ '../views/reports/ReportEdit.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/reports/:slug',
    name: 'ReportDetail',
    props: true,
    component: () => import(/* webpackChunkName: "ReportsEdit" */ '../views/reports/ReportDetail.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/roles/:roleId/permissions',
    name: 'PermissionsRol',
    props: (route) => ({ params: { ...route.query, ...route.params } }),
    component: () => import(/* webpackChunkName: "ReportsEdit" */ '../views/permissions/PermissionsDetail.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/users/:userId/permissions',
    name: 'PermissionsUser',
    props: (route) => ({ params: { ...route.query, ...route.params } }),
    component: () => import(/* webpackChunkName: "ReportsEdit" */ '../views/permissions/PermissionsDetail.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/about',
    name: 'About',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "About" */ '../views/AboutView.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/login/AuthView.vue'),
  },
  {
    path: '/auth',
    name: 'Login Auth',
    meta: { requiresAuth: false },
    component: () => import(/* webpackChunkName: "Auth" */ '../views/auth/AuthView.vue'),
    props: (route) => ({ params: { ...route.query, ...route.params } }),
  },
  {
    path: '/recovery-password',
    name: 'Recovery Password',
    component: () => import(/* webpackChunkName: "Recovery Password" */ '../views/recoveryPassword/RecoveryPassword.vue'),
    props: (route) => ({ params: { ...route.query, ...route.params } }),
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: () => import(/* webpackChunkName: "Reset Password" */ '../views/recoveryPassword/ResetPassword.vue'),
    props: (route) => ({ params: { ...route.query, ...route.params } }),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "Profile" */ '../views/users/ProfileView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/externalProfile',
    name: 'ExternalProfile',
    meta: { requiresAuth: false },
    component: () => import(/* webpackChunkName: "ExternalProfile" */ '../views/users/ExternalProfileView.vue'),
    props: (route) => ({ params: { ...route.query, ...route.params } }),
  },
  {
    path: '/token',
    name: 'Token',
    component: () => import(/* webpackChunkName: "Token" */ '../views/AuthToken.vue'),
    props: (route) => ({ params: { ...route.query, ...route.params } }),
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});

router.beforeEach((to, from, next) => {
  // debugger;
  if (from.path === '/auth' || from.path === '/externalProfile') {
  //   next({
  //     path: from.path,
  //     query: { redirect: to.fullPath },
  //   });
    return false;
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const session = store.getters.isLoggedIn;
    if (!session || session === null) {
      return next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    }
    // next();
  }
  return next();
});

export default router;
