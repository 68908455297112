<template>
  <div class="view-modal">
    <el-dialog
      :title="data.name ?? data.title"
      width="30%"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      :before-close="handleClose">
      <div class="content-modal-detail scroll-gray">
        <el-descriptions
          direction="vertical"
          :column="1"
          border
        >
          <el-descriptions-item
            v-for="(item, key, i) in data"
            :key="`item-${i}`"
            :label="key">
            {{ item }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ViewModal',
  props: ['showModal', 'data'],
  data() {
    return {
      dialogVisible: this.showModal,
    };
  },
  methods: {
    handleClose() {
      this.$root.$emit('closeModal');
    },
  },
};
</script>

<style lang="scss">
  .view-modal{
    .el-descriptions-item__label {
      text-transform: capitalize;
      font-weight: bold;
    }

    .content-modal-detail{
      height: 500px;
      overflow-y: scroll;
      padding: 0 20px 0 0;
      @media (max-width: 424px) {
        height: 400px;
      }
    }
  }
</style>
