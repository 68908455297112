<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="custom-tags">
    <el-tag
      :key="tag"
      v-for="tag in dynamicTags"
      closable
      effect="dark"
      :color="tag"
      :disable-transitions="false"
      @close="handleClose(tag)">
      {{tag}}
    </el-tag>

    <template v-if="inputVisible">
      <template v-if="type === 'tag_text' || type === 'url'">
        <el-input
          class="input-new-tag"
          v-model="inputValue"
          ref="saveTagInput"
          size="mini"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        >
        </el-input>
      </template>

      <template v-else-if="type === 'tag_color_picker'">
        <el-color-picker
          class="input-new-tag"
          v-model="inputValue"
          ref="saveTagInput"
          @change="handleInputConfirm"
        ></el-color-picker>
      </template>
    </template>
    <el-button
      v-else
      class="button-new-tag"
      size="small"
      @click="showInput">+ {{placeholder ?? inputText}}</el-button>
  </div>
</template>

<script>

export default {
  name: 'CustomTags',
  props: ['value', 'type', 'placeholder'],
  components: {
  },
  data() {
    return {
      dynamicTags: [],
      inputVisible: false,
      inputValue: '',
    };
  },
  watch: {
  },
  computed: {
    inputText() {
      if (this.type === 'tag_text') {
        return 'Add Column';
      }
      if (this.type === 'tag_color_picker') {
        return 'Add Color';
      }
      if (this.type === 'url') {
        return 'Add Redirect URL';
      }

      return 'Add Tag';
    },
  },
  methods: {
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      this.$emit('input', JSON.stringify(this.dynamicTags));
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        if (this.type === 'tag_text') {
          this.$refs.saveTagInput.$refs.input.focus();
        }
      });
    },

    handleInputConfirm() {
      const { inputValue } = this;
      if (inputValue) {
        this.dynamicTags.push(inputValue);

        this.$emit('input', JSON.stringify(this.dynamicTags));
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
  },
  mounted() {
    this.dynamicTags = JSON.parse(this.value);
  },
};
</script>

<style lang="scss">
  .custom-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .button-new-tag {
      height: 32px;
      line-height: 30px;
      padding-top: 0;
      padding-bottom: 0;
    }
    .input-new-tag {
      width: 90px;
      margin-left: 10px;
      vertical-align: bottom;
    }
  }
</style>
