<template>
  <div class="role-modal">
    <el-dialog
      width="50%"
      center
      :title="`${state} ${title}`"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :before-close="handleClose">
      <el-form
        label-position="top"
        :model="permissionDataForm"
        status-icon
        ref="permissionDataForm"
        label-width="120px"
        class="demo-ruleForm"
        :hide-required-asterisk="true"
        :rules="rules">

        <el-form-item label="Permission Name" prop="name">
          <el-input
            type="text"
            v-model="permissionDataForm.name"
            @input="forceUppercase($event, permissionDataForm, 'identifier')"
            autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="Identifier" prop="identifier">
          <el-input
            type="text"
            :disabled="this.state === 'create' ? false : true"
            v-model="permissionDataForm.identifier"
            @input="forceUppercase($event, permissionDataForm, 'identifier')"
            autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="Parent Permission" prop="fatherId">
          <el-select
            v-model="permissionDataForm.fatherId"
            clearable
            filterable
            placeholder="Select parent permission">
            <el-option
              v-for="item in permissionsApplication"
              :key="item.id"
              :label="`${item.name} ${item.father ? '('+item.father+')' : ''}`"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            class="btn-secondary-app"
            :loading="loading"
            type="info"
            @click="action('permissionDataForm')"
            @keypress="action('permissionDataForm')"
            style="width:100%;">{{ state === 'create' ? 'Create' : 'Save' }}
          </el-button>
        </el-form-item>

      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import ApiSSO from '@/lib/ApiSSO';

export default {
  name: 'PermissionsModal',
  props: ['showModal', 'title', 'state', 'permissionId', 'applicationId'],
  components: {},
  data() {
    return {
      dialogVisible: this.showModal,
      loading: false,
      disabled: false,
      permissionDataForm: {},
      permissionsApplication: {},
      rules: {
        name: [
          {
            type: 'string',
            required: true,
            message: 'Please enter profile name.',
            trigger: 'blur',
          },
        ],
        fatherId: [
          {
            type: 'string',
            required: true,
            message: 'Please enter parent permission.',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.$root.$emit('closeModal');
      }
    },
  },
  methods: {
    forceUppercase(e, o, prop) {
      if (this.state === 'create') {
        const value = e.toUpperCase();
        this.$set(o, prop, value.replace(/[^a-zA-Z]+/g, '_'));
      }
    },
    handleClose() {
      this.$root.$emit('closeModal');
    },
    async getPermissionsApplication() {
      try {
        const r = await ApiSSO.get(`/permissions/application/${this.applicationId}`);
        if (r.success) {
          this.permissionsApplication = r.data;
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async getPermission() {
      try {
        const r = await ApiSSO.get(`/permissions/${this.permissionId}`);
        if (r.success) {
          this.permissionDataForm = { ...r.data };
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    action(form) {
      this.loading = true;
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.state === 'create') {
            return this.sendCreate();
          }
          if (this.state === 'update') {
            return this.sendUpdate();
          }
        }
        this.loading = false;
        return false;
      });
    },
    reset(form) {
      this.$refs[form].resetFields();
    },
    async sendCreate() {
      try {
        this.permissionDataForm.applicationId = this.applicationId;
        const r = await ApiSSO.post('/permissions', this.permissionDataForm);
        if (r.success) {
          this.loading = false;
          this.dialogVisible = false;
          this.reset('permissionDataForm');
          this.$root.$emit('reloadDataPermissions');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async sendUpdate() {
      try {
        this.loading = true;
        this.permissionDataForm.id = this.permissionId;
        const r = await ApiSSO.put('/permissions', this.permissionDataForm);
        if (r.success) {
          this.dialogVisible = false;
          this.reset('permissionDataForm');
          this.$root.$emit('reloadDataPermissions');
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    if (this.permissionId && this.state === 'update') {
      await this.getPermission();
    }
    if (this.permissionId && this.state === 'create') {
      this.permissionDataForm.fatherId = this.permissionId;

      this.rules.identifier = [
        {
          type: 'string',
          required: true,
          message: 'Please enter Identifier.',
          trigger: 'blur',
        },
      ];
    }
    await this.getPermissionsApplication();
  },
};
</script>

<style lang="scss">
.role-modal {
  .el-form--label-top .el-form-item__label {
    padding: 0 !important;
  }

  .el-form-item__content {
    width: 100%;
    margin-right: 0;
  }

  .el-form-item__label {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #3C3A3A;
    letter-spacing: -0.01em;
  }

  .content-modal-user {
    height: 500px;
    overflow-y: scroll;
    padding: 0 20px 0 0;
    @media (max-width: 424px) {
      height: 400px;
    }
  }

  .el-dialog {
    padding: 40px 50px;
    width: 80% !important;
    border-radius: 8px;
    height: auto;
    margin-top: 8vh !important;

    @media (min-width: 720px) {
      width: 40% !important;
    }

    .el-dialog__header {
      padding: 20px 0 10px !important;

      .el-dialog__title {
        text-transform: capitalize;
        font-family: 'Work Sans' !important;
        display: flex;
        align-self: left;
      }
    }

    .el-dialog__body {
      padding: 0;
    }
  }

  .btn-secondary-app {
    width: 134px !important;
    height: 36px !important;
  }
}
</style>
