<template class="login">
  <div class="login-screen">
    <el-main class="login-main">
      <div class="header-login">
        <img class="logo" :src="logo" alt="logo">
      </div>
      <div class="title">
        <p>Reset Password</p>
      </div>
      <div class="container-login">
        <template>
          <el-form
            label-position="top"
            :model="preResetForm"
            status-icon
            :rules="rules"
            ref="preResetForm"
            label-width="120px"
            class="demo-preResetForm"
            :hide-required-asterisk="true">
            <el-form-item label="Password" prop="password">
              <el-input
                :type="showPassword"
                v-model="preResetForm.password"
                autocomplete="off">
                <el-button
                  @click="handleShowPassword"
                  slot="append"
                  icon="el-icon-view">
                </el-button>
              </el-input>
            </el-form-item>
            <el-form-item label="Validate Password" prop="validatePassword">
              <el-input
              :type="showPasswordValidate"
              v-model="preResetForm.validatePassword"
              autocomplete="off">
                <el-button
                  @click="handleShowPasswordValidate"
                  slot="append"
                  icon="el-icon-view">
                </el-button>
              </el-input>
            </el-form-item>

            <p
              class="invalid-credentials"
              v-if="validPreResetForm">Verify your username.
            </p>

            <el-form-item>
              <el-button
                class="btn-primary mt-button-reset"
                :loading="loading"
                type="info"
                @click="preResetPassword('preResetForm')"
                @keypress="preResetPassword('preResetForm')">
                Reset Password
              </el-button>
            </el-form-item>
          </el-form>
        </template>
      </div>
      <a @click="goToLogin"  @keypress="goToLogin">Login</a>
    </el-main>
  </div>
</template>

<script>
import logo from '@/assets/sso_icon.png';
import ApiSSO from '../../lib/ApiSSO';

export default {
  name: 'ResetPassword',
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please enter the password again'));
      } else if (value !== this.preResetForm.password) {
        callback(new Error("Two passwords doesn't match!"));
      } else {
        callback();
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please enter the password'));
      } else {
        // Check if password is at least 12 characters long
        if (value.length < 12) {
          callback(new Error('Check if password is at least 12 characters long'));
        }

        // Check if password contains at least one lowercase letter
        if (!/[a-z]/.test(value)) {
          callback(new Error('Check if password contains at least one lowercase letter'));
        }

        // Check if password contains at least one uppercase letter
        if (!/[A-Z]/.test(value)) {
          callback(new Error('Check if password contains at least one uppercase letter'));
        }

        // Check if password contains at least one number
        if (!/[0-9]/.test(value)) {
          callback(new Error('Check if password contains at least one number'));
        }

        // Check if password contains at least one special character
        if (!/[\W]/.test(value)) {
          callback(new Error('Check if password contains at least one special character'));
        }

        callback();
      }
    };
    return {
      showPassword: 'password',
      showPasswordValidate: 'password',
      dataRecovery: {},
      logo,
      preReset: false,
      loading: false,
      validPreResetForm: false,
      validForm: false,
      preResetForm: {
        password: '',
        validatePassword: '',
        applicationID: process.env.VUE_APP_ID_SSO,
      },
      rules: {
        password: [
          {
            validator: validatePass,
            type: 'string',
            required: true,
            trigger: 'blur',
          },
        ],
        validatePassword: [
          {
            validator: validatePass2,
            type: 'string',
            required: true,
          },
        ],
      },
    };
  },
  methods: {
    handleShowPassword() {
      if (this.showPassword === 'password') {
        this.showPassword = 'text';
      } else {
        this.showPassword = 'password';
      }
    },
    handleShowPasswordValidate() {
      if (this.showPasswordValidate === 'password') {
        this.showPasswordValidate = 'text';
      } else {
        this.showPasswordValidate = 'password';
      }
    },
    preResetPassword(formName) {
      this.loading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          return this.resetPassword();
        }
        this.loading = false;
        return false;
      });
    },
    resetPassword() {
      this.preResetForm.token = this.$route.query.token;
      ApiSSO.post('/reset-password', this.preResetForm).then((r) => {
        if (r) {
          if (r.success) {
            this.loading = false;
            this.$message({
              message: 'Your new password has been created',
              type: 'success',
            });
            this.goToLogin();
            this.$refs.preResetForm.reset();
          } else {
            this.$message({
              message: r.message,
              type: 'warning',
            });
          }

          this.loading = false;
        }
      }).catch((e) => {
        console.log(e);
        this.loading = false;
        this.validPreLoginForm = true;
      });
    },
    goToLogin() {
      if (this.$route.query.url) {
        window.location.href = window.atob(this.$route.query.url);
      } else {
        this.$router.push({ path: '/login' });
      }
    },
  },
  mounted() {
  },
};
</script>
