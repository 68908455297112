/* eslint-disable */
// eslint-disable-next-line to ignore the next line.

export default {
  capitalizeString(data) {
    let str = data.replaceAll('_', ' ');
    str = str.toLowerCase();
    return str.split(' ').map((i) => {
      if (i.length > 2) {
        return i.charAt(0).toUpperCase() + i.slice(1);
      }

      return i;
    }).join(' ');
  },

  generateRandomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },
};
