<template class="nav-bar">
  <div>
    <div class="header fixed-top" style="height:auto;z-index:3;">
      <el-header>
        <el-menu
          background-color="#3FB5E5"
          text-color="#FFFFFF"
          active-text-color="#FFFFFF"
          :default-active="activeMenu"
          class="el-menu-demo navbar-sso"
          mode="horizontal"
          @select="handleSelect"
          :router="true"
        >
          <el-menu-item index="false" disabled class="logo">
            <img
              class="nav_logo"
              style="cursor: pointer"
              @click="toHome"
              @keypress="toHome"
              :src="logo"
              alt="nav_logo"
            />
          </el-menu-item>

          <el-menu-item disabled class="cursor-pointer d-flex fr bb-none mb-menu">
            <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                  <span class="mb">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </span>
              <el-dropdown-menu slot="dropdown" class="popper-mb">
                <el-dropdown-item command="clients"
                >Clients
                </el-dropdown-item
                >
                <el-dropdown-item command="new-user"
                >Create New User
                </el-dropdown-item
                >
                <el-dropdown-item command="manage-user"
                >Manage Users
                </el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </el-menu-item>

          <div class="container-elements-navbar">
            <!-- <el-menu-item class="fr" index="/">
              <i class="mdi mdi-home"></i>Home
            </el-menu-item> -->
            <el-menu-item class="home desktop cursor-pointer d-flex fr bb-none mb-menu" index="/">
              Home
            </el-menu-item>

            <el-menu-item class="fr desktop" index="/clients">Clients
            </el-menu-item>

            <el-menu-item disabled class="cursor-pointer d-flex fr bb-none">
              <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link desktop">
                  Manage Users<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown" class="popper">
                  <el-dropdown-item command="new-user"
                  >Create New User
                  </el-dropdown-item
                  >
                  <el-dropdown-item command="manage-user"
                  >Manage Users
                  </el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </el-menu-item>

            <el-menu-item disabled class="cursor-pointer d-flex fr bb-none admin">
              <avatar
                v-if="!this.user.image"
                :fullname="user.name"
                class="avatar-text"
                color="#214BEB"
              ></avatar>

              <el-avatar
                v-else
                shape="circle"
                fit="cover"
                :size="50"
                :src="this.user.image"
              ></el-avatar>

              <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link d-flex">
                <div id="v-step-0" class="user-info">
                  <div class="logout d-flex">
                    <div class="username">{{ user.name }}</div>
                    <!-- <div class="status">Enabled / Disabled</div> -->
                  </div>
                  <div>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </div>
                </div>
              </span>
                <el-dropdown-menu slot="dropdown" class="popper">
                  <el-dropdown-item command="mange-2fa">Manage 2FA</el-dropdown-item>
                  <el-dropdown-item command="reset">Reset Password</el-dropdown-item>
                  <el-dropdown-item command="logout" class="logout-button">Logout</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-menu-item>

            <!-- <el-menu-item
              disabled
              class="align-items-center cursor-pointer d-flex fr bb-none"
            >
              <avatar
                v-if="!this.user.image"
                :fullname="user.name"
                class="avatar-text"
                color="#214BEB"
              ></avatar>

              <el-avatar
              v-else
              shape="circle"
              fit="cover"
              :size="50"
              :src="this.user.image"
            ></el-avatar>
            </el-menu-item> -->
          </div>
        </el-menu>
      </el-header>
    </div>

    <reset-password-modal
      v-if="showResetPassword"
      :showModal="showResetPassword"
      title="Reset Password"
    >
    </reset-password-modal>

    <manage-twofa-modal
      v-if="showManage2FA"
      :showModal="showManage2FA"
      title="Manage 2FA">
    </manage-twofa-modal>

    <v-tour
      name="myTour"
      :steps="steps"
      :callbacks="myCallbacks"
      :options="myOptions"
    ></v-tour>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import logo from '@/assets/sso_icon_white.png';
import Avatar from 'vue-avatar-component';
import ResetPasswordModal from '@/components/Modals/ResetPasswordModal.vue';
import ManageTwofaModal from '@/components/Modals/ManageTwofaModal.vue';

export default {
  name: 'NavBar',
  props: [],
  components: {
    Avatar,
    ResetPasswordModal,
    ManageTwofaModal,
  },
  data() {
    return {
      logo,
      activeMenu: '/',
      showResetPassword: false,
      showManage2FA: false,
      steps: [
        {
          target: '#v-step-0',
          // header: {
          //   title: 'ENABLE TWO FACTOR AUTHENTICATION',
          // },
          content: 'For added security, it is highly encouraged to enable <strong>two factor authentication</strong>.<br>Enable it here.',
          params: {
            highlight: false,
          },
        },
        {
          target: '#v-step-1',
        },
      ],
      myCallbacks: {
        onNextStep: this.finishTourCallback,
      },
      myOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: 'Later',
          buttonNext: 'Activate Now',
        },
      },
    };
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
    ...mapState(['token', 'user']),
  },
  methods: {
    ...mapMutations(['setUser', 'setToken']),
    setRoute() {
      this.activeMenu = this.$route.fullPath;
    },
    handleSelect(key) {
      this.activeMenu = key;
    },
    logout() {
      this.setUser(null);
      this.setToken(null);
      this.$router.push({ path: '/login' });
    },
    toHome() {
      this.activeMenu = '/';
      this.$router.push({ path: '/' });
    },
    handleCommand(command) {
      if (command === 'reset') {
        this.showResetPassword = true;
      } else if (command === 'logout') {
        this.logout();
      } else if (command === 'new-user') {
        console.log('new-user');
        this.$root.$emit('openModalCreateUser');
      } else if (command === 'manage-user') {
        // this.$router.push({ path: '/users' });
        window.location.href = '/users';
        console.log('manage-user');
      } else if (command === 'mange-2fa') {
        // this.showManage2FA = true;
        if (this.$route.path !== '/profile') {
          this.$router.push({ path: '/profile' });
        }
        console.log('mange-2fa');
      } else if (command === 'clients') {
        this.$router.push({ path: '/clients' });
        console.log('clients');
      }
    },
    closeModal() {
      this.showResetPassword = false;
      this.showManage2FA = false;
    },
    finishTourCallback() {
      // this.showManage2FA = true;
      if (this.$route.path !== '/profile') {
        this.$router.push({ path: '/profile' });
      }
    },
  },
  mounted() {
    this.setRoute();
    this.$root.$on('closeModal', this.closeModal);

    if (!this.user.confirmedTwoFA) {
      this.$tours.myTour.start();
    }
  },
};
</script>
<style lang="scss" scoped>
.logout-button {
  width: 100%;
  text-align: center;
}

.header, .el-header {
  height: auto !important;
}

.el-header {
  .mb-menu {
    display: grid;
    order: -1;

    @media (min-width: 720px) {
      order: 0;
    }
  }
}

.navbar-sso {
  padding: 0 25px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  height: 77.22px;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto;
  align-content: center;
}

.container-elements-navbar {
  display: flex;
  align-items: center;
  margin-left: 0;
  height: 77.22px;
  justify-content: end;

  @media (min-width: 720px) {
    margin-left: auto;
  }

  .desktop {
    display: none;
    @media (min-width: 720px) {
      display: block;
    }
  }

  .desktop:hover {
    background-color: #3FB5E5 !important;
  }

  .el-dropdown-link {
    font-size: 18px !important;

    .user-info {
      .logout {
        display: none;
        @media (min-width: 720px) {
          display: block;
        }
      }
    }
  }

}

.mb {
  display: block;
  @media (min-width: 720px) {
    display: none;
  }

  span {
    display: block;
    width: 20px;
    height: 3px;
    right: 0;
    margin-bottom: 3px;
    background: white;
    border-radius: 2px;
  }
}

.popper {
  width: 100%;
  text-align: center;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  margin-left: -5px !important;
  top: 65px !important;
  @media (min-width: 720px) {
    width: 20%;
    margin-left: 0px !important;
    margin-right: -50px !important;
  }
}

.popper-mb {
  width: 100%;
  text-align: center;
  margin-left: -5px !important;
  top: 88px !important;
  @media (min-width: 720px) {
    visibility: hidden;
  }
}

.el-menu-item {
  padding-left: 0;
  font-size: 18px;
}

.admin {
  padding-right: 0;
  height: 100%;
}

.admin:last-child {
  background: #299BC9 !important;
  display: grid;
  align-content: center;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  column-gap: 13px;
  padding: 0 40px;
}

.el-menu--horizontal > .el-menu-item {
  display: flex;
  align-items: center;
  height: 100%;
}

.el-icon-arrow-down {
  color: #FFFFFF;
}

.logo {
  display: grid;
  justify-content: center;
  padding-right: 0;

  @media (min-width: 720px) {
    order: -1;
    justify-content: start;
  }

  .nav_logo {
    width: 120px !important;
    height: auto !important;
    @media (min-width: 720px) {
      width: 150px !important;
    }
  }
}

.mb-menu {
  justify-self: end;
  font-weight: 700;
  font-size: 18px;
}

.mb-menu:hover {
  background-color: #3FB5E5;
}

.mb-menu:focus {
  background-color: #3FB5E5;
}

.mb-menu:active {
  background-color: #3FB5E5;
}

.avatar {
  margin-right: 10px;
}
</style>
