<template>
  <div class="manage-2fa-modal">
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :before-close="handleClose"
      >
      <div
        v-loading="loading"
        v-if="!user.confirmedTwoFA"
        :class="twoFaForm.enabledTwoFA ? 'content-modal-user scroll-gray' : ''"
      >
        <div>
          <el-alert
            type="info"
            description="Allow authentication through an authenticator app."
            :closable="false"
            show-icon
          >
          </el-alert>
        </div>

        <el-form
          label-position="top"
          :model="twoFaForm"
          status-icon
          ref="twoFaForm"
          label-width="130px"
          class="demo-ruleForm"
          :hide-required-asterisk="true"
          :rules="rules"
        >
          <el-form-item label="Enable" prop="enabledTwoFA">
            <el-switch
              @change="handleEnableTwoFA"
              v-model="twoFaForm.enabledTwoFA"
              active-color="#214BEB"
              inactive-color="#2D3648"
              :active-value=1
              :inactive-value=0
              >
            </el-switch>
          </el-form-item>

          <div v-if="QRCode" class="setup-authenticator">
            <h3>Setup Authenticator</h3>
            <ol>
              <li>Get the Authenticator App from the Play Store/App Store</li>

              <div class="stores">
                <a target="_blank" href="https://apps.apple.com/us/app/google-authenticator/id388497605?platform=iphone">
                  <img :src="appleStoreLogo" alt="appleStoreLogo">
                </a>

                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US">
                  <img :src="playStoreLogo" alt="playStoreLogo">
                </a>
              </div>

              <li>In the App select <b>Set up account.</b></li>
              <li>Choose <b>Scan barcode</b> or manually enter the code <br/>
                <div class="img-code">
                  <el-image
                    style="width: 200px; height: 200px"
                    :src="QRCode"
                    fit="cover">
                  </el-image>
                </div>
                <div class="code">
                  <span>{{ keyCode }}</span>
                </div>
              </li>

              <li>
                <div>
                  Confirm your configuration by submitting a <b>OTP</b>
                </div>
                <br/>
                <div>
                  <el-form-item label="" prop="code">
                    <el-input
                      type="text"
                      placeholder="Insert code here..."
                      v-model="twoFaForm.code"
                      autocomplete="off"
                      v-mask="'######'"
                    ></el-input>
                    <div v-if="!codeIsValid" class="el-form-item__error">
                      Invalid OTP! Please try again.
                    </div>
                  </el-form-item>
                </div>
              </li>

              <el-form-item class="text-center" v-if="QRCode">
                <el-button
                  class="btn-secondary"
                  :loading="loading"
                  type="info"
                  @click="validateForm('twoFaForm')"
                  @keypress="validateForm('twoFaForm')"
                >
                  Submit
                </el-button>
              </el-form-item>
            </ol>
          </div>
        </el-form>
      </div>

      <div
        v-else
        v-loading="loading"
        class="two-fa-active"
      >
        <div>
          <el-alert
            type="info"
            description="2 Factor Authentication is enabled on your account at all times."
            :closable="false"
            effect="dark"
            show-icon>
          </el-alert>
        </div>

        <div>
          <el-button
            class="btn-secondary"
            :loading="loading"
            type="info"
            @click="handleRemoveTwoFa()"
            @keypress="handleRemoveTwoFa()"
          >
            Remove Authenticator App
          </el-button>
        </div>

      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import playStoreLogo from '@/assets/play_store.png';
import appleStoreLogo from '@/assets/apple_store.png';
import ApiSSO from '../../lib/ApiSSO';

export default {
  name: 'ManageTwofaModal',
  components: {},
  props: ['showModal', 'title', 'data'],
  data() {
    return {
      playStoreLogo,
      appleStoreLogo,
      dialogVisible: this.showModal,
      loading: false,
      twoFaForm: {
        enabledTwoFA: false,
        confirmedTwoFA: false,
        userId: '',
        applicationId: '',
        code: '',
      },
      rules: {
        code: [
          {
            required: true,
            message: 'Please enter OTP.',
            trigger: 'blur',
          },
          {
            min: 6,
            message: 'OTP must be at least 6 characters.',
            trigger: 'blur',
          },
        ],
      },
      QRCode: '',
      keyCode: '',
      codeIsValid: true,
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.$root.$emit('closeModal');
      }
    },
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    ...mapMutations(['setUser', 'setToken', 'setRefreshToken']),
    handleClose() {
      this.$root.$emit('closeModal');
    },
    handleEnableTwoFA() {
      this.loading = true;

      this.twoFaForm.userId = this.user.id;
      this.twoFaForm.applicationId = this.user.applicationId;

      ApiSSO.post('/twoFaEnable', this.twoFaForm)
        .then((r) => {
          if (r) {
            if (r.success) {
              const {
                accessToken,
                refreshToken,
                QRCode,
                keyCode,
              } = r.data;

              const user = this.$jwt.decode(accessToken).data;

              this.QRCode = QRCode;
              this.keyCode = keyCode;

              this.setUser(user);
              this.setToken(accessToken);
              this.setRefreshToken(refreshToken);
            }
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validateForm(formName) {
      this.loading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          return this.sendCode();
        }
        this.loading = false;
        return false;
      });
    },
    sendCode() {
      this.loading = true;

      this.twoFaForm.userId = this.user.id;
      this.twoFaForm.applicationId = this.user.applicationId;

      ApiSSO.post('/twoFaConfirm', this.twoFaForm)
        .then((r) => {
          if (r) {
            if (r.success) {
              const {
                accessToken,
                refreshToken,
                codeIsValid,
              } = r.data;

              const user = this.$jwt.decode(accessToken).data;

              this.codeIsValid = codeIsValid;

              this.setUser(user);
              this.setToken(accessToken);
              this.setRefreshToken(refreshToken);
            }
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleRemoveTwoFa() {
      this.loading = true;

      this.$confirm('Are you sure to remove 2 Factor Authentication?', {
        type: 'warning',
      })
        .then(() => {
          this.removeTwoFa();
        })
        .catch(() => {
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeTwoFa() {
      this.loading = true;

      this.twoFaForm.userId = this.user.id;
      this.twoFaForm.applicationId = this.user.applicationId;

      ApiSSO.post('/twoFaRemove', this.twoFaForm)
        .then((r) => {
          if (r) {
            if (r.success) {
              const {
                accessToken,
                refreshToken,
              } = r.data;

              const user = this.$jwt.decode(accessToken).data;

              this.setUser(user);
              this.setToken(accessToken);
              this.setRefreshToken(refreshToken);

              this.twoFaForm.enabledTwoFA = this.user.enabledTwoFA;
              this.twoFaForm.confirmedTwoFA = this.user.confirmedTwoFA;
              this.twoFaForm.code = '';
              this.QRCode = '';
              this.keyCode = '';
            }
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.twoFaForm.enabledTwoFA = this.user.enabledTwoFA;
    if (this.user.enabledTwoFA && !this.user.confirmedTwoFA) {
      this.handleEnableTwoFA();
    }
  },
};
</script>

<style lang="scss">
.manage-2fa-modal {
  .two-fa-active {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
  }

  .setup-authenticator {
    display: flex;
    flex-direction: column;
    align-items: center;
    ol {
      list-style: decimal !important;
      li{
        padding: 10px;

        &::marker {
          font-weight: bold !important;
        }
        .img-code{
          display: flex;
          justify-content: center;
        }
        .code{
          display: flex;
          justify-content: center;
        }
      }
    }
    .stores {
      display: flex;
      column-gap: 20px;
      justify-content: center;
      img {
        height: 30px;
      }

    }
  }
  .content-modal-user{
    height: 500px;
    overflow-y: scroll;
    padding: 0 20px 0 0;
    @media (max-width: 424px) {
      height: 400px;
    }
  }
}
</style>
