<template class="login">
  <div class="login-screen">
    <el-main class="login-main">
      <div class="header-login">
        <img class="logo" :src="logo" alt="logo">
      </div>
      <div class="title">
        <p>Sign in to dashboard</p>
      </div>
      <div class="container-login">
        <template>
          <el-form
              label-position="top"
              :model="preLoginForm"
              status-icon
              :rules="rules"
              ref="preLoginForm"
              label-width="120px"
              class="demo-preLoginForm"
              :hide-required-asterisk="true"
            >
            <el-form-item label="Username" prop="email">
              <el-input type="email" v-model="preLoginForm.email" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Password" prop="password">
              <el-input
                type="password"
                v-model="preLoginForm.password"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <p
              class="invalid-credentials"
              v-if="validPreLoginForm">Verify your username or password.
            </p>

            <el-form-item>
              <el-button
                class="btn-primary"
                :loading="loading"
                type="info"
                @click="preSignIn('preLoginForm')"
                @keypress="preSignIn('preLoginForm')"
                >
                  Login
              </el-button>
            </el-form-item>
          </el-form>
        </template>
      </div>

      <div class="mt-20 mb-20 text-center">
        <span>Or</span>
      </div>

      <div class="google-auth">
        <el-button
          @click="authGoogle"
          type="primary">
            <i class="mdi mdi-google"></i> Sign up with Google
        </el-button>
      </div>

      <router-link to="/recovery-password"> Forgot Password</router-link>
    </el-main>

    <verify-two-fa-modal
      v-if="showVerifyTwoFaModal"
      :showModal="showVerifyTwoFaModal"
      :data="dataLogin"
      title="2FA AUTHENTICATION"
      @valid2Fa="valid2Fa"
    >
    </verify-two-fa-modal>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import logo from '@/assets/sso_icon.png';
import VerifyTwoFaModal from '@/components/Modals/VerifyTwoFaModal.vue';
import ApiSSO from '../../lib/ApiSSO';

export default {
  name: 'AuthView',
  components: {
    VerifyTwoFaModal,
  },
  data() {
    return {
      showVerifyTwoFaModal: false,
      dataLogin: {},
      logo,
      preLogin: false,
      loginId: '',
      loading: false,
      validPreLoginForm: false,
      validForm: false,
      preLoginForm: {
        email: '',
        password: '',
        applicationID: process.env.VUE_APP_ID_SSO,
      },
      rules: {
        email: [
          {
            type: 'email',
            required: true,
            message: 'Please enter your user email.',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: 'Please enter your password.',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  watch: {
    $route: {
      handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
  methods: {
    ...mapMutations(['setUser', 'setToken', 'setRefreshToken']),
    preSignIn(formName) {
      this.loading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          return this.loginUser();
        }
        this.loading = false;
        return false;
      });
    },
    loginUser() {
      ApiSSO.post('/authenticate', this.preLoginForm).then((r) => {
        if (r) {
          if (r.success) {
            this.loading = false;
            const { accessToken, refreshToken } = r.data;
            const jwtData = this.$jwt.decode(accessToken);
            const user = jwtData.data;
            user.exp = jwtData.exp;

            if (user.confirmedTwoFA) {
              this.dataLogin = {
                user,
                accessToken,
                refreshToken,
              };

              this.showVerifyTwoFaModal = true;
            } else {
              this.setUser(user);
              this.setToken(accessToken);
              this.setRefreshToken(refreshToken);

              this.$router.push({ path: '/' });
            }
          } else {
            this.$message({
              message: r.message,
              type: 'warning',
            });
          }

          this.loading = false;
        }
      }).catch((e) => {
        console.log(e);
        this.loading = false;
        this.validPreLoginForm = true;
      });
    },
    valid2Fa(data) {
      if (data.codeIsValid) {
        this.setUser(data.user);
        this.setToken(data.accessToken);
        this.setRefreshToken(data.refreshToken);

        this.$router.push({ path: '/' });
      }
    },
    async authGoogle() {
      window.location.href = `${process.env.VUE_APP_API_SSO}/googleAuth?applicationId=${process.env.VUE_APP_ID_SSO}&redirectUrl=${process.env.VUE_APP_REDIRECT_URL}`;
    },
  },
  mounted() {
    if (this.isLoggedIn) {
      this.$router.push({ path: '/' });
    }
  },
};
</script>

<style lang="scss">
.google-auth{
  display: flex;
  align-items: center;
  justify-content: center;
  .el-button{
    width: fit-content !important;
  }
}
</style>
