<template>
  <div class="report-bar">
    <div class="container_banner" :class="alignBtn" :style="bannerUrl">
      <div class="content-banner-report container">
        <img :src="brandLogoUrl" alt="" class="logo_brand"/>
        <h2 class="title_report">{{ title }}</h2>
      </div>

      <div class="showBtn" v-if="btnData?.show">
        <el-button
          :class="btnData?.classes"
          @click="action"
          plain
        ><i class="mdi" :class="btnData?.icon"></i> {{ btnData?.text }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ReportBar',
  props: ['bannerUrl', 'brandLogoUrl', 'title', 'btnData'],
  components: {},
  data() {
    return {
      // example btnDAta
      // btnData: {
      //   show: true,
      //   classes: 'btn-primary',
      //   text: 'Copy Embed Code',
      //   icon: 'mdi-link-variant',
      //   actionType: '',
      // },
    };
  },
  computed: {
    alignBtn() {
      return this.btnData?.show ? 'alignBtn' : null;
    },
  },
  methods: {
    action() {
      console.log(this.btnData.actionType);
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss">
.report-bar {
  .container_banner {
    margin-bottom: 40px;
    height: 254px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .content-banner-report {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .logo_brand {
    border-radius: 50%;
    width: 175px;
  }

  .title_report {
    font-weight: 700;
    font-size: 53px;
    color: #ffffff;
    margin-left: 44px;
  }

  .showBtn {
    margin: 50px;
  }

  .alignBtn {
    display: flex;
    align-items: center;
  }
}
</style>
