<template>
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page.sync="current"
    :page-size="pageSize"
    :page-sizes="[10, 20, 50, 100, 500, 1000]"
    :layout="layout"
    :total="total"
    :small="true"
    :pager-count="pagerCount">
  </el-pagination>
</template>
<script>

export default {
  name: 'SimplePaginator',
  props: ['pageSize', 'total', 'currentPage', 'layout', 'pagerCount'],
  components: {},
  data() {
    return {
      current: 1,
    };
  },
  watch: {
    currentPage(val) {
      this.current = val;
    },
  },
  computed: {},
  methods: {
    handleCurrentChange(val) {
      this.$emit('currentChange', val);
    },
    handleSizeChange(value) {
      let val = value;
      if (val === 'All') {
        val = 0;
      }
      this.$emit('sizeChange', val);
      setTimeout(() => {
        const inputPagination = document.getElementsByClassName('el-input__inner');
        if (inputPagination !== undefined) {
          inputPagination.forEach((input) => {
            const item = input;
            if (item.value !== undefined) {
              item.value = item.value.replace('/page', '');
            }
          });
        }
      }, 1);
    },
    replacePaginator() {
      const selectPaginator = document.getElementsByClassName('el-select-dropdown__list');
      if (selectPaginator !== undefined) {
        selectPaginator.forEach((select) => {
          const items = select.children;
          items.forEach((element) => {
            const item = element.children[0];
            item.innerHTML = item.innerHTML.replace('/page', '');
            item.innerText = item.innerText.replace('/page', '');
          });
        });
      }
      setTimeout(() => {
        const inputPagination = document.getElementsByClassName('el-input__inner');
        if (inputPagination !== undefined) {
          inputPagination.forEach((input) => {
            const item = input;
            if (item.value !== undefined) {
              item.value = item.value.replace('/page', '');
            }
          });
        }
      }, 500);
    },
  },
  mounted() {
    this.current = this.currentPage;
    this.replacePaginator();
  },
};
</script>

<style lang="scss">
// .el-pagination {
//   display: grid;
//   grid-template-rows: auto;
//   grid-template-columns: max-content max-content auto auto max-content auto auto;
//   justify-content: self-start;
//   align-items: center;
//   margin: 50px 0 0;
// }

.el-pagination__jump {
  justify-self: end;
}

.el-pagination {
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin: 50px 0 0;
}

.el-pagination::after,
.el-pagination::before {
  display: none;
}

.el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon {
  color: var(--default-header-font-color);
}

.el-pager {
  .number.active {
    color: #3FB5E5;
  }
}

.el-pagination__sizes,
.el-pagination__editor {
  .el-select--mini {
    .el-input--suffix {
      .el-input__inner {
        border-color: #E4E8E8;
      }

      .el-input__inner:hover {
        border-color: #3FB5E5;
      }

      .el-input__inner:active {
        border-color: #3FB5E5;
      }

      .el-input__inner:focus {
        border-color: #3FB5E5;
      }
    }
  }
}

.el-select-dropdown__list {
  font-family: 'Work Sans';

  .selected {
    color: #3FB5E5;
  }
}

.el-pagination__editor {
  font-family: 'Work Sans';

  .el-input__inner {
    border-color: #E4E8E8;
  }

  .el-input__inner:hover {
    border-color: #3FB5E5;
  }

  .el-input__inner:active {
    border-color: #3FB5E5;
  }

  .el-input__inner:focus {
    border-color: #3FB5E5;
  }
}

.el-pager li.active {
  border-bottom: 2px solid !important;
}

.el-pagination__sizes:before {
  content: "Displaying";
}

.el-pagination__jump {
  visibility: hidden;

  &:before {
    content: "Page";
    visibility: visible;
    position: absolute;
  }

  div {
    visibility: visible;
  }
}

.el-select-dropdown.el-popper {
  min-width: 60px !important;
}

.el-pagination--small button, .el-pagination--small span:not([class*=suffix]) {
  height: 100% !important;
}

@media (max-width: 768px) {
  .el-pagination__total, .el-pagination__sizes, .el-pagination__jump {
    display: none !important;
  }
}

.el-pagination .el-select .el-input {
  width: 67px !important;
}

.el-pagination::after, .el-pagination::before {
  display: none !important;
}

</style>
