import VueJWT from 'vuejs-jwt';
import UUID from 'vue-uuid';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import Vue from 'vue';
import vueCustomElement from 'vue-custom-element';
import VueTour from 'vue-tour';
import VueMask from 'v-mask';
import store from './store';
import router from './router';
import App from './App.vue';

require('vue-tour/dist/vue-tour.css');

Vue.config.productionTip = false;

// configure language
locale.use(lang);

Vue.use(ElementUI);
Vue.use(UUID);
Vue.use(VueJWT);
Vue.use(vueCustomElement);
Vue.use(VueTour);
Vue.use(VueMask);

App.store = store;

App.router = router;

Vue.customElement('sso-app', App, {
  shadow: false,
  // beforeCreateVueInstance(root) {
  //   const rootNode = root.el.getRootNode();

  //   if (rootNode instanceof ShadowRoot) {
  //     root.shadowRoot = rootNode;
  //   } else {
  //     root.shadowRoot = document.head;
  //   }
  //   return root;
  // },
});
