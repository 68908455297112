<template>
  <div class="report-modal">
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
      :before-close="handleClose">

      <template v-if="create">
        <el-form
            :model="createReportForm"
            status-icon
            :rules="rules"
            ref="createReportForm"
            label-width="120px"
            class="demo-ruleForm"
            :hide-required-asterisk="true"
          >
          <el-form-item label="Title" prop="title">
            <el-input type="text" v-model="createReportForm.title" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button
              class="btn-secondary"
              :loading="loading"
              type="info"
              @click="createReport('createReportForm')"
              @keypress="createReport('createReportForm')"
            >
              Create
            </el-button>
          </el-form-item>
        </el-form>
      </template>
    </el-dialog>
  </div>
</template>

<script>

import ApiMILO from '../../lib/ApiMILO';

export default {
  name: 'ReportModal',
  components: {},
  props: ['showModal', 'title', 'create', 'edit', 'brandId', 'reportId'],
  data() {
    return {
      dialogVisible: this.showModal,
      loading: false,
      logoUrl: '',
      disabled: false,
      createReportForm: {
        title: '',
        brandId: this.brandId,
      },
      editReportForm: {
        visible: false,
      },
      rules: {
        title: [
          {
            type: 'string',
            required: true,
            message: 'Please enter report title.',
            trigger: 'blur',
          },
        ],
      },
      rulesEdit: {
        title: [
          {
            type: 'string',
            required: true,
            message: 'Please enter report title.',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.$root.$emit('closeModal');
      }
    },
  },
  methods: {
    handleClose() {
      this.$root.$emit('closeModal');
    },
    createReport(formName) {
      this.loading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          return this.sendCreate();
        }
        this.loading = false;
        return false;
      });
    },
    sendCreate() {
      ApiMILO.post(`/brands/${this.brandId}/report`, this.createReportForm).then((r) => {
        if (r) {
          if (r.success) {
            this.loading = false;
            this.dialogVisible = false;
            this.resetForm('createReportForm');
            this.$root.$emit('reloadReports');
          }
          this.loading = false;
        }
      }).catch((e) => {
        console.log(e);
        this.loading = false;
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    getReport() {
      ApiMILO.getOne(`/report/${this.reportId}`).then((r) => {
        if (r) {
          if (r.success) {
            this.editReportForm = r.data;
          }
        }
      }).catch((e) => {
        console.log(e);
        this.loading = false;
      });
    },
    editReport(formName) {
      this.loading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          return this.sendEdit();
        }
        this.loading = false;
        return false;
      });
    },
    sendEdit() {
      ApiMILO.put(`/report/${this.reportId}`, this.editReportForm).then((r) => {
        if (r) {
          if (r.success) {
            this.loading = false;
            this.dialogVisible = false;
            this.resetForm('editReportForm');
            this.$root.$emit('reloadReports');
          }
        }
      }).catch((e) => {
        console.log(e);
        this.loading = false;
      });
    },
  },
  mounted() {
    if (this.reportId) {
      this.getReport();
    }
  },
};
</script>

<style lang="scss">

.report-modal {
}
</style>
