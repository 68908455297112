<template>
  <div v-if="report" class="report-view container">
    <div class="row">
      <el-row>
        <report-bar
          :bannerUrl="bannerUrl"
          :brandLogoUrl="brandLogoUrl"
          :title="report.title"
          :btnData="btnData"
        ></report-bar>
      </el-row>
      <el-row>
        <el-col
          :span="columnsSettingWidget(element, 'columns')"
          :key="element.id"
          v-for="element in widgets"
        >
          <div class="list-group-item widgetadd" :key="element.id">
            <img
              :src="element.urlImage"
              alt="Widget"
              class="widgetAddImageCopy"
            />
            {{ element.name }}
          </div>
        </el-col>

      </el-row>
    </div>
  </div>
</template>

<script>
import ReportBar from '@/components/Bars/ReportBar.vue';
import ImgNotFound from '@/assets/img_not_found.png';
import ApiMILO from '@/lib/ApiMILO';
import widgetBar from '@/assets/widget_types/bar.jpg';
import widgetMultiLine from '@/assets/widget_types/multiline.jpg';

export default {
  name: 'ReportDetail',
  props: ['reportId', 'slug'],
  components: {
    ReportBar,
  },
  data() {
    return {
      report: {},
      widgets: [],
      ImgNotFound,
      widgetBar,

      btnData: {
        show: true,
        classes: 'btn-primary',
        text: 'Copy Embed Code',
        icon: 'mdi-link-variant',
        actionType: '',
      },
    };
  },
  computed: {
    bannerUrl() {
      let result = '';
      if (this.report?.brand?.brand_settings) {
        const arr = this.report.brand.brand_settings;
        const obj = arr.find((element) => element.name === 'header_hero');
        result = obj && obj.value !== ''
          ? `background-image:url('${obj.value}')`
          : 'background-color: grey';
      }
      return result;
    },
    brandLogoUrl() {
      let result = '';
      if (this.report?.brand?.brand_settings) {
        const arr = this.report.brand.brand_settings;
        const obj = arr.find((element) => element.name === 'header_logo');
        result = obj && obj.value !== '' ? obj.value : ImgNotFound;
      }
      return result;
    },
  },
  methods: {
    columnsSettingWidget(widget, setting) {
      let result = '';
      if (widget.widget_settings_categories) {
        const arrCategory = widget.widget_settings_categories;

        const objCategory = arrCategory.find((element) => element.name === 'general');
        const arr = objCategory.widget_settings;
        const obj = arr.find((element) => element.name === setting);
        result = obj && obj.value !== '' ? 24 / obj.value : '12';
      }
      return result;
    },
    async getReport() {
      try {
        const r = await ApiMILO.getOne(`/report/${this.reportId}`);
        if (r.success) {
          this.report = r.data;
          this.getWidget();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getWidget() {
      try {
        const { data, success } = await ApiMILO.get(`/report/${this.reportId}/widgets`);
        const urlsImages = [
          {
            id: 1,
            url: widgetBar,
          },
          {
            id: 2,
            url: widgetMultiLine,
          },
        ];

        if (success) {
          this.widgets = Object.values(data).map((widget) => {
            const imgWidget = urlsImages.find(
              (widgetImage) => widgetImage.id === widget.widgetTypeId,
            );

            if (imgWidget) {
              return { ...widget, urlImage: imgWidget.url, cols: 12 };
            }
            return { ...widget, cols: 12 };
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.getReport();
  },
};
</script>

<style lang="scss">
.principal-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10em, 1fr));
  grid-gap: 20px;
}

.content-draggable {
  background-color: #fafafa;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px dashed #313131;
  border-radius: 6px;
  margin-right: 15px;
  min-height: 540px;
  position: relative;
}
.dragArea.list-group {
  width: 100%;
  height: 540px;
}

.widgetadd,
.widgetList {
  position: relative;
  background: #f8f8f8;
  // background: #fff;
  // border: 1px solid #333;
  padding: 20px;
  border-radius: 15px;
  display: grid;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;

  button {
    position: absolute;
    top: 10px;
    right: 20px;
    background: transparent;
    border: 0;
    font-size: 20px;
    cursor: pointer;
  }
}

.report-view {
  .el-col {
    padding: 10px;
  }
}

.widgetAddImage {
  cursor: move;
}
.widgetAddImageCopy {
  cursor: pointer;
}

.tabs-widgets {
  display: inline-grid;
  position: fixed;
  top: 45%;
  overflow: hidden;
  width: 33.5%;
  .el-tabs {
    background: #f8f8f8;
    border-radius: 6px;
  }

  .el-tabs--card > .el-tabs__header .el-tabs__nav {
    width: 100%;
    border: none;
  }

  .el-tabs--card > .el-tabs__header {
    border: none;
    background: #313131;
    border-radius: 0px 6px;
  }

  .el-tabs--card > .el-tabs__header .el-tabs__item {
    border: none;
    color: #ffff;
    font-weight: 700;
    text-align: center;
  }

  .el-tabs__item {
    width: 50%;
    border: none;
  }

  .el-tabs__item.is-active {
    color: #313131 !important;
    background: #f8f8f8 !important;
  }
}
.form-edit-report {
  overflow: auto;
}
.scroll-gray::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f8f8f8;
}

.scroll-gray::-webkit-scrollbar {
  width: 5px;
  background-color: #f8f8f8;
}

.scroll-gray::-webkit-scrollbar-thumb {
  background-color: #31313126;
  border: 1px solid #f8f8f8;
}

.report-edit {
  .el-divider {
    &__text {
      background-color: #f8f8f8 !important;
      text-align: center;
    }
  }

  .close-edit-widget {
    text-align: end;
    margin: 20px;
    span{
      cursor: pointer;
      font-size: 20px;
    }
  }
}
</style>
