<template>
  <div class="rel-user-modal">
    <el-dialog
      width="50%"
      center
      :title="title"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
    >
      <div class="content-modal-user scroll-gray">
        <el-form
          label-position="top"
          :model="userDataForm"
          status-icon
          ref="userDataForm"
          label-width="120px"
          class="demo-ruleForm"
          :hide-required-asterisk="true"
          :rules="rules"
        >
          <el-form-item label="User" prop="userId">
            <el-select
              v-model="userDataForm.userId"
              filterable
              placeholder="Select user"
            >
              <el-option
                v-for="item in users"
                :key="item.id"
                :label="`${item.firstname} ${item.lastname} - ${item.email}`"
                :value="item.id"
              >
              <div class="items-user">
                <avatar
                  v-if="!item?.profilePicture"
                  :fullname="`${item.firstname} ${item.lastname}`"
                  class="avatar-text"
                  color="#214BEB"
                ></avatar>
                <el-avatar
                  v-else
                  :size="30"
                  :src="item.profilePicture"
                ></el-avatar>
                <span>{{ `${item.firstname} ${item.lastname}` }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.email }}</span>
              </div>
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Roles" prop="roles">
            <el-select
              filterable
              v-model="userDataForm.roles"
              multiple
              placeholder="Select roles"
            >
              <el-option
                v-for="item in rolesApplications"
                :key="item.roleId"
                :label="`${item.role} - (${item.application})`"
                :value="item.roleId"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button
              class="btn-secondary-app"
              :loading="loading"
              type="info"
              @click="action('userDataForm')"
              @keypress="action('userDataForm')"
              style="width: 100%"
            >
              Save
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ApiSSO from '@/lib/ApiSSO';
import Avatar from 'vue-avatar-component';

export default {
  name: 'RelUserModal',
  props: ['showModal', 'title', 'applicationId'],
  components: {
    Avatar,
  },
  data() {
    return {
      dialogVisible: this.showModal,
      loading: false,
      disabled: false,
      userDataForm: {
        userId: '',
        roles: [],
      },
      rolesCopy: [],
      users: [],
      applications: [],
      rolesApplications: [],
      rules: {
        userId: [
          {
            type: 'string',
            required: true,
            message: 'Please select an user.',
            trigger: 'blur',
          },
        ],
        roles: [
          {
            type: 'array',
            required: true,
            message: 'Please select a role.',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.$root.$emit('closeModal');
      }
    },
  },
  methods: {
    handleClose() {
      this.$root.$emit('closeModal');
    },
    action(form) {
      this.loading = true;
      this.$refs[form].validate((valid) => {
        if (valid) {
          return this.sendCreate();
        }
        this.loading = false;
        return false;
      });
    },
    reset(form) {
      this.$refs[form].resetFields();
    },
    async sendCreate() {
      try {
        const r = await ApiSSO.post('/linkUserAppRole', {
          ...this.userDataForm,
          applicationId: this.applicationId,
        });
        if (r.success) {
          this.loading = false;
          this.dialogVisible = false;
          this.reset('userDataForm');
          this.$root.$emit('reloadDataUsers');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    getUsers() {
      ApiSSO.get(`/usersNotInApp/${this.applicationId}`)
        .then((r) => {
          if (r.success) {
            this.users = Object.values(r.data);
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {});
    },
    async getRolesByApps() {
      try {
        const r = await ApiSSO.post('/roles/applications', [this.applicationId]);
        if (r.success) {
          return r.data;
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }

      return [];
    },
  },
  async mounted() {
    this.getUsers();
    this.rolesApplications = Object.values(await this.getRolesByApps());
  },
};
</script>

<style lang='scss'>
  .el-scrollbar{
    .items-user {
      display: flex;
      align-content: center;
      align-items: center;
      column-gap: 10px;
    }

    .avatar-text {
      width: 30px !important;
      height: 30px !important;
      td {
        font-size: 12px !important;
      }
    }
  }
.rel-user-modal {
  .el-form--label-top .el-form-item__label {
    padding: 0 !important;
  }

  .el-form-item__content {
    width: 100%;
    margin-right: 0;
  }

  .el-form-item__label {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #3c3a3a;
    letter-spacing: -0.01em;
  }

  .content-modal-user {
    height: auto;
    overflow-y: auto;
    padding: 0 20px 0 0;
    @media (max-width: 424px) {
      height: 400px;
    }
  }

  .el-dialog {
    padding: 40px 50px;
    width: 80% !important;
    border-radius: 8px;
    height: auto;
    margin-top: 8vh !important;

    @media (min-width: 720px) {
      width: 40% !important;
    }
    @media (max-width: 768px) {
      padding: 30px 20px;
    }

    .el-dialog__header {
      padding: 10px 0 !important;

      .el-dialog__title {
        font-family: "Work Sans" !important;
        display: flex;
        align-self: left;
      }
    }

    .el-dialog__body {
      padding: 0;
    }
  }

  .btn-secondary-app {
    width: 134px !important;
    height: 36px !important;
  }
}
</style>
