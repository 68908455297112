<template>
  <div class="permissions-user-modal">
    <el-dialog
      width="50%"
      center
      :close-on-click-modal="false"
      :title="`${title} for ${dataUser.email}`"
      :visible.sync="dialogVisible"
      :before-close="handleClose">
      <div class="search-table">
        <el-input
          @change="inputKeyUp"
          v-model="searchText"
          clearable
          size="mini"
          prefix-icon="el-icon-search"
          placeholder="Search press enter"/>
      </div>
      <div class="content-modal-user scroll-gray">
        <div v-loading="loading" class="permissions">
          <v-jstree
              v-if="renderComponent"
              :data="dataTree"
              show-checkbox
              multiple
              allow-batch
              whole-row
              @item-click="itemClick"
              ref="tree"
            >
              <template slot-scope="$">
                  <div
                    style="display: inherit; width: 200px"
                    >
                      <i
                        :class="$.vm.themeIconClasses"
                        role="presentation"
                        v-if="!$.model.loading"></i>
                      {{$.model.text}}
                      <button
                        title="Add new son permission"
                        style="border: 0px; background-color: transparent; cursor: pointer;"
                        @click="addNewSonPermission($.vm, $.model, $event)"
                        >
                        <i class="mdi mdi-plus-circle"></i>
                      </button>
                  </div>
              </template>
            </v-jstree>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ApiSSO from '@/lib/ApiSSO';
import VJstree from 'vue-jstree';

export default {
  name: 'PermissionsUserModal',
  props: ['showModal', 'title', 'dataUser'],
  components: {
    VJstree,
  },
  data() {
    return {
      dialogVisible: this.showModal,
      loading: false,
      disabled: false,
      searchText: '',
      dataTree: [],
      renderComponent: true,
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.$root.$emit('closeModal');
      }
    },
  },
  methods: {
    handleClose() {
      this.$root.$emit('closeModal');
    },
    async getDataTree() {
      try {
        this.loading = true;
        const r = await ApiSSO.post('/permissions/user', {
          userId: this.dataUser.id,
        });
        if (r.success) {
          this.dataTree = Object.values(r.data);
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async forceRerender() {
      // Remove MyComponent from the DOM
      this.renderComponent = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;
    },
    async itemClick(node) {
      this.loading = true;
      if (node.model.selected) {
        await this.registerPermission(node.model.id);
      } else {
        await this.removePermission(node.model.id);
      }
      this.forceRerender();
    },
    async registerPermission(permissionId) {
      try {
        const r = await ApiSSO.post('/permissions/user/add', {
          userId: this.dataUser.id,
          permissionId,
        });
        if (r.success) {
          this.$message({
            message: 'Permission updated successfully.',
            type: 'success',
          });
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async removePermission(permissionId) {
      try {
        const r = await ApiSSO.delete(`/permissions/user/${this.dataUser.id}/delete/${permissionId}`);
        if (r.success) {
          this.$message({
            message: 'Permission removed successfully.',
            type: 'success',
          });
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    inputKeyUp() {
      const text = this.searchText;
      const patt = new RegExp(text);

      this.$refs.tree.handleRecursionNodeChilds(this.$refs.tree, (node) => {
        if (text !== '' && node.model !== undefined) {
          const str = node.model.text;
          if (patt.test(str.toLowerCase())) {
            // eslint-disable-next-line
            /* eslint-disable */
            node.$el.querySelector('.tree-anchor').style.color = 'red';
          } else {
            // eslint-disable-next-line
            /* eslint-disable */
            node.$el.querySelector('.tree-anchor').style.color = '#000';
          } // or other operations
        } else {
          // eslint-disable-next-line
          /* eslint-disable */
          node.$el.querySelector('.tree-anchor').style.color = '#000';
        }
      });
    },
    addNewSonPermission(node ,item, e) {
      e.stopPropagation()
        console.log('🚀 -------------------------------------------------------------------------------------🚀');
        console.log('🚀 ~ file: PermissionsModal.vue ~ line 255 ~ addNewSonPermission ~ node ,item', node ,item);
        console.log('🚀 -------------------------------------------------------------------------------------🚀');
        // var index = node.parentItem.indexOf(item);
        // node.parentItem.splice(index, 1);
    },
  },
  async mounted() {
    await this.getDataTree();
  },
};
</script>

<style lang="scss">
  .permissions-user-modal{
    .el-form--label-top .el-form-item__label {
      padding: 0 !important;
    }

    .el-form-item__label {
      line-height: 30px !important;
      color: #214BEB !important;
      font-weight: 700;
      letter-spacing: -0.01em;
    }

    .content-modal-user{
        height: 500px;
        overflow-y: scroll;
        padding: 0 20px 0 0;
        @media (max-width: 424px) {
          height: 400px;
        }
      }
  }
</style>
