<template class="login">
  <div class="login-screen">
    <el-main class="login-main">
      <div class="header-login">
        <img class="logo" :src="logo" alt="logo">
      </div>
      <div class="title">
        <p>Recovery Password</p>
      </div>
      <div class="container-login">
        <template>
          <el-form
            label-position="top"
            :model="preRecoveryForm"
            status-icon
            :rules="rules"
            ref="validPreRecoveryForm"
            label-width="120px"
            class="demo-validPreRecoveryForm"
            :hide-required-asterisk="true">
            <el-form-item label="Username" prop="email">
              <el-input type="email"
                        v-model="preRecoveryForm.email"
                        autocomplete="off"></el-input>
            </el-form-item>

            <p
              class="invalid-credentials"
              v-if="validPreRecoveryForm">Verify your username.
            </p>

            <el-form-item>
              <el-button
                class="btn-primary mt-button-reset"
                :loading="loading"
                type="info"
                @click="preRecovery('validPreRecoveryForm')"
                @keypress="preRecovery('validPreRecoveryForm')">
                Reset Password
              </el-button>
            </el-form-item>
          </el-form>
        </template>
      </div>
      <a @click="goToLogin" @keypress="goToLogin">Login</a>
    </el-main>
  </div>
</template>

<script>
import logo from '@/assets/sso_icon.png';
import ApiSSO from '../../lib/ApiSSO';

export default {
  name: 'RecoveryPassword',
  props: ['params'],
  data() {
    return {
      logo,
      preLogin: false,
      loading: false,
      validPreRecoveryForm: false,
      validForm: false,
      preRecoveryForm: {
        email: '',
        urlRedirect: this.$route.query.url ? this.$route.query.url : (this.params.redirectUrl ?? ''),
      },
      rules: {
        email: [
          {
            type: 'email',
            required: true,
            message: 'Please enter your user email.',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    preRecovery(formName) {
      this.loading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          return this.recoveryPassword();
        }
        this.loading = false;
        return false;
      });
    },
    recoveryPassword() {
      ApiSSO.post('/recovery-password', this.preRecoveryForm).then((r) => {
        if (r) {
          if (r.success) {
            this.loading = false;
            this.$message({
              message: 'You will receive an email with the process to recover your password.',
              type: 'success',
            });

            this.goToLogin();

            this.$refs.validPreRecoveryForm.reset();
          } else {
            this.$message({
              message: r.message,
              type: 'warning',
            });
          }

          this.loading = false;
        }
      }).catch((e) => {
        console.log(e);
        this.loading = false;
        this.validpreRecoveryForm = true;
      });
    },
    goToLogin() {
      if (this.$route.query.url) {
        window.location.href = window.atob(this.$route.query.ur);
      } else if (this.params.redirectUrl) {
        window.location.href = window.atob(this.params.redirectUrl);
      } else {
        // Change the route when is used by SSO internal
        this.$router.push({ path: '/login' });
      }
    },
  },
  mounted() {
  },
};
</script>
