<template>
  <div class="home">
    <applications-view></applications-view>
  </div>
</template>

<script>
import ApplicationsView from '@/views/applications/ApplicationsView.vue';

export default {
  name: 'HomeView',
  components: {
    ApplicationsView,
  },
  data() {
    return { visible: false };
  },
  methods: {
  },
  mounted() {},
};
</script>
