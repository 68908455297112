<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="reports">
      <breadcrumb-view :items="breadcrumb"></breadcrumb-view>

      <principal-bar
        :title="principalBarTitle"
        btnText="Create New"
        actionType="showModalCreate"
      ></principal-bar>
    <simple-table
      :data="reports"
      :tableColumns="tableColumns"
      :loading="loading"
      :firstColumn="firstColumn"
    ></simple-table>

    <report-modal v-if="showModalCreate"
                  :showModal="showModalCreate"
                  :create="true"
                  title="Create Report"
                  :brandId="brandId">
    </report-modal>
  </div>
</template>

<script>

import ApiMILO from '@/lib/ApiMILO';
import PrincipalBar from '@/components/Bars/PrincipalBar.vue';
import SimpleTable from '@/components/Tables/SimpleTable.vue';
import ReportModal from '@/components/Modals/ReportModal.vue';
import BreadcrumbView from '@/components/Bars/BreadcrumbView.vue';

export default {
  name: 'ReportsView',
  props: ['brandId', 'brandName'],
  components: {
    PrincipalBar,
    SimpleTable,
    ReportModal,
    BreadcrumbView,
  },
  computed: {
    principalBarTitle() {
      const { brandName } = this;
      const result = `Select Report for ${brandName}`;
      return result;
    },
  },
  data() {
    return {
      loading: false,
      showModalCreate: false,
      showModalEdit: false,
      reports: [],
      paginator: {
        currentPage: 0,
        pageSize: 10,
        total: 0,
        page: 1,
      },
      tableColumns: [
        {
          prop: 'title',
          label: 'Report Name',
          sortable: true,
        },
        {
          prop: 'createdAt',
          label: 'Created Date',
          sortable: true,
          format: 'fromNow',
        },
      ],
      firstColumn: {
        show: true,
        icon: 'mdi-chart-box-outline',
      },
      breadcrumb: [
        {
          string: 'Brands',
          active: false,
        },
        {
          string: 'Reports',
          active: true,
        },
      ],
    };
  },
  methods: {
    getReports() {
      this.loading = true;
      const paginate = `page_size=${this.paginator.pageSize}&page=${this.paginator.page}`;

      ApiMILO.get(`/brands/${this.brandId}/report?${paginate}`).then((r) => {
        this.loading = false;
        this.reports = Object.values(r.data);
        if (r.paginator.total_pages) {
          this.paginator.currentPage = r.paginator.page;
          this.paginator.page_size = r.paginator.page_size;
          this.paginator.total = r.paginator.count;
        }
      }).catch((e) => {
        this.loading = true;
        console.log(e);
      });
    },
    closeModal() {
      this.showModalCreate = false;
      this.showModalEdit = false;
    },
    openModalCreate() {
      this.showModalCreate = true;
    },
    goToEdit(data) {
      if (data.type === 'Reports') {
        this.$router.push({ name: 'ReportEdit', params: { reportId: data.id } });
      }
    },
    goToView(data) {
      this.$router.push({ name: 'ReportDetail', params: { reportId: data.id, slug: data.slug } });
    },
  },
  mounted() {
    const { brandId } = this;
    if (brandId == null) {
      this.$router.push({ path: '/' });
    }
    this.getReports();
    this.$root.$on('closeModal', this.closeModal);
    this.$root.$on('openModalCreate', this.openModalCreate);
    this.$root.$on('reloadReports', this.getReports);
    this.$root.$on('editRow', this.goToEdit);
    this.$root.$on('viewRow', this.goToView);
  },
};
</script>

<style lang="scss">
.banner_report {
  width: 100%;
}

.container_banner {
  margin-bottom: 40px;
  height: 254px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.logo_brand {
  border-radius: 50%;
  width: 175px;
}

.title_report {
  font-weight: 700;
  font-size: 53px;
  color: #ffffff;
  margin-left: 44px;
}

</style>
